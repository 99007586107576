<template>
  <div class="app-container">
    <!-- 查询开始 -->
    <div>
      <div class="addbutton"><el-button type="primary" round plain @click="addEmployee" icon="el-icon-circle-plus">创建</el-button></div>
      <!-- 弹框 -->
      <el-dialog title="创建员工" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="名称" :label-width="formLabelWidth"  prop="nickName"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="form.nickName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="电话" :label-width="formLabelWidth">
            <el-input v-model="form.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth"  prop="password"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="form.password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="角色" :label-width="formLabelWidth" prop="roleValue"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'change' }]">
            <el-select v-model="roleValue" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="(item,index) in roleList"
                :key="index"
                :label="item.label"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="CreateList"  :disabled="addEmpButton">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查询结束 -->
    <!-- 表格开始 -->
    <div>
      <el-table
      border
       v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableDataList"
        style="width:100%;"
        max-height="650"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="250">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="登录账号" width="200">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="角色" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.roleId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.authorized?'男':'女' }}</span>
          </template>
        </el-table-column>
         <el-table-column label="创建时间" width="200">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最近登录时间" width="200">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.lastLoginTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="employeesEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain @click="employeesDel(scope.row)">删除</el-button>
            <el-button size="mini" type="success" plain @click="uploadPwd(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 删除成功弹窗开始 -->
      <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDel" width="15%">
        <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleDel = false">取 消</el-button>
          <el-button type="primary" @click="SureDel">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 删除成功弹窗结束 -->
      <!-- 编辑员工开始 -->
      <el-dialog title="编辑员工" :visible.sync="DataEdits">
        <el-form :model="employeeData">
          <el-form-item label="名称" :label-width="formLabelWidth" prop="nickname"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="employeeData.nickname" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="电话" :label-width="formLabelWidth" prop="mobile"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="employeeData.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="角色" :label-width="formLabelWidth" prop="roleId"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'change' }]">
            <el-select v-model="employeeData.roleId" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="(item,index) in roleList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="DataEdits = false">取 消</el-button>
          <el-button type="primary" @click="DetermineEdit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑员工结束 -->
        <!-- 重置密码弹窗开始 -->
        <el-dialog title="重置密码" top="30vh" :visible.sync="dialogVisibleSet" width="15%">
          <div style="margin-bottom:20px;">
            <el-input placeholder="请输入需要修改的密码" v-model="pwd" clearable></el-input>
          </div>
          <el-input placeholder="请再次确认密码" v-model="pwdTwo" clearable></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSet = false">取 消</el-button>
            <el-button type="primary" @click="SureSetPwd">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 重置密码弹窗开始 -->
    </div>
    <!-- 表格结束 -->
  </div>
</template>

<script>
import Query from "../../components/Query/Query.vue";
import Paging from "../../components/Paging/Paging.vue";
export default {
  components: {
    Query,
    Paging
  },
  data() {
    return {
      loading:true,
      Numbering: "", //编号
      dialogFormVisible: false, //弹框值
      formLabelWidth: "80px", //弹框输入框宽度
      form: {
        //创建员工弹框表单
        nickName: "",
        mobile: "",
        password: ""
      },
      addEmpButton:false,
      show: false, //高级搜索
      //搜索结束
      tableDataList: [],

      employeesDelData: {}, //删除员工数据
      dialogVisibleDel: false, //删除弹框
      roleList: [], //所有角色
      roleValue: "", //选中的角色

      DataEdits: false, //编辑弹框
      employeeData: {}, //编辑数据
      roleTbMap: [],

      pwd:'',//重置密码
      pwdTwo:'',
      dialogVisibleSet:false,
      userid:'',

    };
  },
  created() {
    this.getEmployees();
  },
  methods: {
    // 获取员工
    getEmployees() {
      let jsonData = JSON.parse(sessionStorage.getItem("user"));
      const { projectContractorId, shopId, level } = jsonData; //用户的工程商id和商户id
      let data = {
        level,
        projectContractorId,
        shopId
      };
      this.$axios.get("/member/query", { params: data }).then(res => {
        this.loading=false;
        let datalist = res.data.userInfoTbPage; //返回数据
        this.roleTbMap = res.data.roleTbMap;
        let number = 0; //定义编号变量
        datalist.map(item => {
          number++;
          item.num = number;
          item.roleIds = item.roleId;
          item.roleId = res.data.roleTbMap[item.roleId];
          if(item.createTime!==undefined){
            item.createTime = this.getTiem(item.createTime);
          }
          if(item.lastLoginTime!==undefined){
            item.lastLoginTime = this.getTiem(item.lastLoginTime);
          }
          if (item.state === 1) {
            item.state = "可用";
          } else {
            item.state = "禁用";
          }
          if (item.authorized === 1) {
            item.authorized = "是";
          } else {
            item.authorized = "否";
          }
          return item;
        });
        this.tableDataList = datalist;
      });
    },
    // 搜索
    search() {
      this.getEmployees();
    },
    // 创建
    addEmployee() {
      this.addEmpButton=false;
      this.dialogFormVisible = true;
      this.getRole();
    },
    // 确认创建
    CreateList() {
      this.addEmpButton=true;
      this.dialogFormVisible = false;
      const { nickName, mobile, password } = this.form;
      let passwords = this.$md5(password.trim()).toUpperCase();
      let id;
      this.roleList.forEach(item => {
        if ((item.name = this.roleValue)) {
          id = item.id;
        }
      });
      const {
        projectContractorId,
        shopId,
        level
      } = this.$store.state.user.users; //用户的工程商id和商户id
      let data = {
        nickName:nickName.trim(),
        mobile:mobile.trim(),
        password:passwords.trim(),
        roleId: id,
        projectContractorId,
        shopId,
        level
      };
      if(nickName!=='' && password!=='' && id!==undefined){
        this.$axios.get("/member/create", { params: data }).then(res => {
        if (res.data.state === 1) {
          this.getEmployees();
          this.DeleteSuccess();
        } else {
         this.DeleteFailure(res.data.msg)
        }
      });
      }else{
        this.$message.error('请输入完整信息');
      }
    },
    // 获取所有角色
    getRole() {
      this.$axios.post("/role/query").then(res => {
        this.roleList = res.data.roleTbPage;
        console.log(this.roleList,'roleList');
      });
    },
    // 编辑员工
    employeesEdit(row) {
      this.DataEdits = true;
      this.employeeData = { ...row };
      this.getRole();
    },
    //确认编辑
    DetermineEdit() {
      const { id, nickname, mobile, password, roleId } = this.employeeData;
      let num;
      if(typeof roleId==='string'){
        num = this.employeeData.roleIds;
      }else{
        num=roleId;
      }
      if(nickname!==''&& password!==''&& num!==undefined){
              this.$axios
        .get("/member/edit", {
          params: {
            userId: id,
            nickName: nickname.trim(),
            mobile:mobile.trim(),
            password,
            roleId:num,
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getEmployees();
            this.DeleteSuccess();
            this.DataEdits = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
      }else{
        this.$message.error('请输入完整信息');
      }
    },
    // 删除员工
    employeesDel(row) {
      this.employeesDelData = row;
      this.dialogVisibleDel = true;
    },
    //确认删除
    SureDel() {
      const { id } = this.employeesDelData;
      this.$axios
        .get("/member/delete", { params: { userId: id } })
        .then(res => {
          if (res.data.state === 1) {
            this.getEmployees();
            this.DeleteSuccess();
            this.dialogVisibleDel = false;
          } else {
           this.DeleteFailure(res.data.msg)
          }
        });
    },
    // 重置密码
    uploadPwd(row) {
      this.userid = row.id;
      this.dialogVisibleSet = true;
    },
    // 确认重置
    SureSetPwd(){
      if(this.pwd==this.pwdTwo && this.pwd!==''){
        let pwds = this.$md5(this.pwd.trim()).toUpperCase();
         this.$axios
            .get("/member/reSetPw", {
              params: {
                userId: this.userid,
                passWord: pwds
              }
            })
            .then(res => {
              if (res.data.state === 1) {
               this.$message({showClose: true,message: res.data.msg,type: 'success'});
                this.pwd = "";
                this.pwdTwo = "";
                this.dialogVisibleSet = false;
              } else {
                this.pwd = "";
                this.pwdTwo = "";
                 this.$message({showClose: true,message: res.data.msg,type: 'error'});
              }
            });
      }else{
         this.$notify({
          title: '警告',
          message: '两次输入的密码不匹配,请重新输入!',
          type: 'warning'
        });
      }
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
        Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.pag {
  position: fixed;
  bottom: 10px;
  right: 0;
}
.el-table .cell {
  text-align: center;
}
/* 高级搜索开始 */
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 50px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
/* 高级搜索结束 */
/* 弹窗头部 */

.el-dialog__title {
  color: white !important;
  font-weight: 600;
  font-size: 22px;
}
.el-button.is-round {
  padding: 10px 25px;
}
.el-col-4 {
  width: 13.66667%;
}
.el-button--mini {
  padding: 5px 5px;
}
.el-table td {
  padding: 10px 0;
}
.el-input{
  width: 93%;
}
.addbutton{
  text-align: right;
  margin-bottom: 20px;
}
</style>